<template>
    <ul class="type-list">
        <li v-for="item in typeList"
            class="type-item"
            :class="currentType ==  item ? 'current' : ''"
            :key="item.type_id"
            @click="changeType(item)">
            {{item=="全部"?"全部房":item}}
        </li>
    </ul>
</template>

<script>
	export default {
		name:"room-type",
		props:{
			typeList:{
				type:Array,
				default:()=>[]
			},
			currentType:{
				type:String,
				required: true
			}
		},
		methods:{
			changeType(item){
				this.$emit('changeType',item)
			},
		},
	}
</script>

<style lang="scss" type="text/scss" scoped>
    @import "~@/assets/style/public.scss";

    .type-list{
        width:100vw;
        overflow-x:scroll;
        overflow-y:hidden;
        height:88px;
        border-bottom:1px solid $border-color;
        background:#FFFFFF;
        color:#666666;
        white-space:nowrap;
    }

    .type-list::-webkit-scrollbar-track-piece {
        background-color: rgba(0, 0, 0, 0);
        border-left: 1px solid rgba(0, 0, 0, 0);
    }
    .type-list::-webkit-scrollbar {
        width: 5px;
        height: 6px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
    }
    .type-list::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
        background-clip: padding-box;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
    }
    .type-list::-webkit-scrollbar-thumb:hover {
        background-color: rgba(0, 0, 0, 0.3);
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
    }
    .type-item{
        display:inline-block;
        text-align:center;
        padding:0 50px;
        font-size:28px;
        line-height:86px;
        position:relative;

        &.current{
            color:$high-light-color;
        }

        &.current:after{
            content:'';
            width:40px;
            height:4px;
            position:absolute;
            left:50%;
            bottom:0;
            margin:0 0 0 -20px;
            z-index:1;
            background:$high-light-color;
        }
    }

</style>
